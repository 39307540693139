/*
IMPORTANT! Always start `grunt` command in dafgardsstorefront/web
to compile files to lower versions. files from /build folder are used in our javaScript.tag
*/

import {sendPostAjax} from "../helpers/sendAjax.js";
import {getFormData} from "../helpers/operators.js";
const runModule = (name) => import(name).then(module => module.default());
const cmsSiteUid = document.querySelectorAll('.js-cms-site-uid')[0];

if (document.querySelectorAll('.js-embedded-form')[0]) {
    runModule('./embedded-form.js');
}

if (cmsSiteUid?.value === 'dafgardsconsumer') {
    runModule('./consumer/header.js');
}

if (cmsSiteUid?.value !== 'dafgardsconsumer') {
    runModule('./auth.js');
}

if (document.querySelectorAll('.js-registration-page')[0]) {
    runModule('./register.js');
}

if (document.querySelectorAll('.js-profile-page')[0]) {
    runModule('./update-profile.js');
}

if (document.querySelectorAll('.js-user-accounts-page')[0]) {
    runModule('./user-accounts.js');
}

if (document.querySelectorAll('.js-company-unit-tree')[0]) {
    runModule('./unit-tree.js');
}

if (document.querySelectorAll('.js-reorder-btn')[0]) {
    runModule('./reorder.js');
}

if (document.querySelectorAll('.js-product-list-page')[0]) {
    runModule('./product-list-page/product-list-page.js');
    runModule('./product-list-page/product-list-dynamic-items.js');
    runModule('./menu-food-controls.js');
}

if (document.querySelectorAll('.js-search-page')[0]) {
    runModule('./product-list-page/product-search-page.js');
}

if (document.querySelectorAll('.js-product-details-page')[0]) {
    runModule('./menu-food-controls.js');
}

if (document.querySelectorAll('.js-main-datepicker-btn')[0]) {
    runModule('./datepicker/header-datepicker.js');
}

if (document.querySelectorAll('.js-no-available-product')[0]) {
    runModule('./datepicker/no-available-products.js');
}

if (document.querySelectorAll('.js-checkout-page')[0]) {
    runModule('./datepicker/checkout-datepicker.js');
    runModule('./checkout.js');
    runModule('./checkout-coupon.js');
}

if (document.querySelectorAll('.js-product-controls')[0] && !document.querySelectorAll('.js-checkout-page')[0]) {
    runModule('./cart/cart-functionality.js');
}

if (document.querySelectorAll('.js-add-my-list')[0]) {
    runModule('./add-my-list.js');
}

if (document.querySelectorAll('.js-quick-order-page')[0]) {
    runModule('./quick-order.js');
}

runModule('./slot-configurator.js');
runModule('./cart/minicart.js')

if (window.location.search.includes('password=true')) {
    $('#reqPwdDialog').modal('show');
    const requestPassForm = document.querySelectorAll('.js-req-pwd-form')[0];
    const resetFormData = document.querySelectorAll('.js-req-pwd-form input');
    const reqPassWrapper = document.querySelectorAll('.js-req-pwd-modal-wrapper')[0];

    requestPassForm.addEventListener('submit', e => {
        e.preventDefault();
        sendPostAjax('/login/pw/request', getFormData(resetFormData), function (){
            $("#req-pwd-message-template").removeClass("hidden");
            $(".js-req-pwd-container").addClass("hidden");
            reqPassWrapper.classList.add("success-msg");
            reqPassWrapper.classList.remove('no-bg');
            ACC.colorbox.resize();
        });
    });
}
